import AddToCart from '@app/Purchase/AddToCart'
import AddToRecipe from '@app/Purchase/AddToRecipe'
import Counter from '@app/Purchase/Counter'
import CounterController from '@app/Purchase/CounterController'
import Consumption from '@app/Purchase/Consumption'
import DiscountCounterController from '@app/Purchase/DiscountCounterController'
import GroupConfigurator from '@app/Purchase/GroupConfigurator'

export default function install(Vue) {
  Vue.component(AddToCart.name, AddToCart)
  Vue.component(AddToRecipe.name, AddToRecipe)
  Vue.component(Counter.name, Counter)
  Vue.component(CounterController.name, CounterController)
  Vue.component(Consumption.name, Consumption)
  Vue.component(DiscountCounterController.name, DiscountCounterController)
  Vue.component(GroupConfigurator.name, GroupConfigurator)
}
