<template lang="pug">
include /mixins

+b.group-filters-wrap
  +b.group-filter-title-wrap
    +b.filter-title
      +b.ds-caption--size_xl.--medium.--height_2xs.P.tt-space_mr--2.is-visible-xl(
      )
        span.icon-filter_n
      +b.ds-caption--size_md.--medium.--semibold.P {{ _('Фильтры') }}
    +b.tt-link.--variant_start.ds-caption--size_md.--semibold.BUTTON(
      :style='{"color": "var(--theme-secondary-light2)"}'
      @click.prevent='handleInputFilter({})'
    ) {{ _('Очистить фильтр') }}
  +b.tt-accordion
    +e.question.--simple.--spaced_bottom-none.--clear-all(
      @click='toggleActive()',
    )
      +e.text
        +b.ds-caption--size_md.--semibold.--color_grey.SPAN  {{ _('Группа красителя') }}
    +e.description
      +b.group-filters-content
        template(v-for='val in info.groups')
          +b.tt-space_mt--6(v-if='!val.props.is_default')
            +b.d-control-input.--appearance_flexible
              +e.element.INPUT(
                type='checkbox'
                :value='val.props.id',
                name='groups'
                :key='`input_${val.props.id}`'
                v-model='checked'
                :input-label='val.props.title',
                :id='`groups:${info.idkey}:${val.id}`'
                @change='submit()'
              )
              +e.LABEL.label--for-checkbox(
                :for='`groups:${info.idkey}:${val.id}`'
              )
                span.fake-link(
                ) {{ val.props.title }}
  +e.controls
    +b.tt-button.BUTTON(
      @click.prevent='$modal.hide("filters-modal")'
    )
      +e.title.SPAN {{ _('Показать') }}
</template>

<script>

import GroupConfiguratorProduct from './GroupConfiguratorProduct.vue'

export default {
  name: 'GroupConfigurator',
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    GroupConfiguratorProduct,
  },
  data() {
    return {
      checked: [],
    }
  },
  methods: {
    submit() {
      this.$emit('input', this.checked)
    },
  },
}
</script>
