<template lang="pug">
include /mixins
+b.group-configurator
  +e.title.H4 {{ _('Выбор оттенка') }} &nbsp; {{ info.product_title }}
  +e.line
    +e.search-wrap
      +e.search.INPUT(
        type='search'
        name='search',
        v-model='search',
        :placeholder="_('Поиск оттенка красителя по номеру')",
        @input='handleSearch'
      )
      +e.search-icon.icon-search
    +e.selected
      +b.selected-switch
        +e.label {{ _('Только выбраные товары') }}
        +e.switch.switch.INPUT.tt-space_ml--3.tt-space_mr--3(
          id="only-selected"
          v-model='onlySelected'
          type="checkbox"
        )
          //- @change='onlySelected = !onlySelected'
  +e.row
    +e.sidebar
      modal-trigger(
        :tag='$options.GroupConfiguratorFilters'
        url='Forms/ModalWrapper'
        name='filters-modal'
        width='100%'
        height='100vh'
        :adaptive='true'
        :groups='groups'
        :events='events'
        idkey='modal'
        @before-open='blockWindow'
        @before-close='unblockWindow'
      )
        template(#default='{ open }')
          +b.filter-title.is-disable-xl(@click='open')
            +b.ds-caption--size_xl.--medium.--height_2xs.P.tt-space_mr--2
              span.icon-filter_n
            +b.ds-caption--size_sm.--medium.P {{ _('Фильтры') }}
      group-configurator-filters.is-visible-xl(
        :info='{groups, idkey: "inline"}'
        @input='handleInputFilter'
      )
    +e.cell
      +b.group-configurator-section(v-for='item in productsList', :key='item.id')
        +e.title.P {{ item.props.title }}
        +b.g-row
          +b.g-cell.g-cols--6.--3-xl(
            v-for='product in item.props.variants',
          )
            group-configurator-product(
              :key='product.id',
              :item='product',
              :count='product.count',
              @updateQuantity='e => save(e, {...item, props: { variants: [] }})'
            )
  +e.results()
    .is-disable-xl
      row(align='center' justify='between')
        cell(v-if='results.cart_bonus_sum')
          row(align='center')
            cell
              +b.cashback-percent--only-icon
                +e.icon.SVG(:title='_("cashback")' xmlns='http://www.w3.org/2000/svg', width='20', height='20', viewBox!='0 0 20 20', fill='none')
                  circle(cx='10' cy='10' r='10' fill='#FF7A00')
                  circle(cx='10' cy='10' r='8.5' fill='#FF7A00' stroke='white')
                  path(d='M10.5398 15C9.74432 15 9.00568 14.8816 8.32386 14.6448C7.65152 14.3989 7.06439 14.0528 6.5625 13.6066C6.07008 13.1603 5.68655 12.6321 5.41193 12.0219C5.13731 11.4117 5 10.7377 5 10C5 9.2623 5.13731 8.58834 5.41193 7.97814C5.68655 7.36794 6.07008 6.83971 6.5625 6.39344C7.06439 5.94718 7.65152 5.60565 8.32386 5.36885C9.00568 5.12295 9.74432 5 10.5398 5C11.5152 5 12.3769 5.16393 13.125 5.4918C13.8826 5.81967 14.5076 6.29326 15 6.91257L12.8835 8.72951C12.59 8.37432 12.2633 8.10109 11.9034 7.90984C11.553 7.70947 11.1553 7.60929 10.7102 7.60929C10.3598 7.60929 10.0426 7.66393 9.75852 7.77322C9.47443 7.88251 9.22822 8.04189 9.01989 8.25137C8.82102 8.46084 8.66477 8.71585 8.55114 9.01639C8.4375 9.30783 8.38068 9.6357 8.38068 10C8.38068 10.3643 8.4375 10.6967 8.55114 10.9973C8.66477 11.2887 8.82102 11.5392 9.01989 11.7486C9.22822 11.9581 9.47443 12.1175 9.75852 12.2268C10.0426 12.3361 10.3598 12.3907 10.7102 12.3907C11.1553 12.3907 11.553 12.2951 11.9034 12.1038C12.2633 11.9035 12.59 11.6257 12.8835 11.2705L15 13.0874C14.5076 13.6976 13.8826 14.1712 13.125 14.5082C12.3769 14.8361 11.5152 15 10.5398 15Z' fill='white')
            cell
              +b.ds-caption--size_md.--semibold.P(
                style='line-height: 1;'
                v-html='getSplittedHtml(results.cart_bonus_sum)'
              )
        cell
          +b.ds-caption--size_md.--semibold.P(
            v-html='getSplittedHtml(results.total_price)'
          )
      row(align='center' justify='between')
        cell
          +b.group-counter {{ getProductsCount }} {{ _('шт') }}
        cell
          +b.tt-button.BUTTON(
            @click='addToCart()'
            :disabled='!results.total_price'
          )
            +e.title.P {{ _('Добавить в корзину') }}
    .is-visible-xl
      row(align='center' justify='between')
        cell
          row(align='center')
            cell
              +b.group-result-text.P {{ _('Количество товаров:') }} &nbsp;
                +b.group-result-text--bold.SPAN {{ getProductsCount }} {{ _('шт') }}
            cell(v-if='results.total_price')
              row(align='center')
                cell
                  +b.group-result-text.P {{ _('Цена за выбранные товары:') }} &nbsp;
                cell
                  +b.group-result-price.SPAN(
                    v-html='getSplittedHtml(results.total_price)'
                  )
            cell(v-if='results.cart_bonus_sum')
              row(align='center')
                cell
                  +b.cashback-percent--only-icon
                    +e.icon.SVG(:title='_("cashback")' xmlns='http://www.w3.org/2000/svg', width='20', height='20', viewBox!='0 0 20 20', fill='none')
                      circle(cx='10' cy='10' r='10' fill='#FF7A00')
                      circle(cx='10' cy='10' r='8.5' fill='#FF7A00' stroke='white')
                      path(d='M10.5398 15C9.74432 15 9.00568 14.8816 8.32386 14.6448C7.65152 14.3989 7.06439 14.0528 6.5625 13.6066C6.07008 13.1603 5.68655 12.6321 5.41193 12.0219C5.13731 11.4117 5 10.7377 5 10C5 9.2623 5.13731 8.58834 5.41193 7.97814C5.68655 7.36794 6.07008 6.83971 6.5625 6.39344C7.06439 5.94718 7.65152 5.60565 8.32386 5.36885C9.00568 5.12295 9.74432 5 10.5398 5C11.5152 5 12.3769 5.16393 13.125 5.4918C13.8826 5.81967 14.5076 6.29326 15 6.91257L12.8835 8.72951C12.59 8.37432 12.2633 8.10109 11.9034 7.90984C11.553 7.70947 11.1553 7.60929 10.7102 7.60929C10.3598 7.60929 10.0426 7.66393 9.75852 7.77322C9.47443 7.88251 9.22822 8.04189 9.01989 8.25137C8.82102 8.46084 8.66477 8.71585 8.55114 9.01639C8.4375 9.30783 8.38068 9.6357 8.38068 10C8.38068 10.3643 8.4375 10.6967 8.55114 10.9973C8.66477 11.2887 8.82102 11.5392 9.01989 11.7486C9.22822 11.9581 9.47443 12.1175 9.75852 12.2268C10.0426 12.3361 10.3598 12.3907 10.7102 12.3907C11.1553 12.3907 11.553 12.2951 11.9034 12.1038C12.2633 11.9035 12.59 11.6257 12.8835 11.2705L15 13.0874C14.5076 13.6976 13.8826 14.1712 13.125 14.5082C12.3769 14.8361 11.5152 15 10.5398 15Z' fill='white')
                cell
                  +b.ds-caption--size_md.--semibold.P(
                    style='line-height: 1;'
                    v-html='getSplittedHtml(results.cart_bonus_sum)'
                  )
        cell
          +b.tt-button.BUTTON(
            @click='addToCart()'
            :disabled='!results.total_price'
          )
            +e.title.P {{ _('Добавить в корзину') }}
</template>

<script>
import {
  GroupParametersGroupsResource,
  GROUP_PARAMETERS_PRODUCT_URL,
} from '@api/store.service'
import {
  changeCartEntity,
  PRECART_CHANGE_URL,
} from '@api/cart.service'

import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy';

import {
  debounce,
} from '@utils/helpers'

import { createResource, getResource, postResource } from '@resource/resource'

import GroupConfiguratorProduct from './GroupConfiguratorProduct.vue'
import GroupConfiguratorFilters from './GroupConfiguratorFilters.vue'

export default {
  name: 'GroupConfigurator',
  props: {
    info: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  components: {
    GroupConfiguratorProduct,
    GroupConfiguratorFilters,
  },
  GroupConfiguratorFilters,
  data() {
    return {
      products: [],
      groups: [],
      groups_pagination: {},
      search: '',
      onlySelected: false,
      checked: [],
      saved: [],
      currentRequest: '',
      results: {},
    }
  },
  created() {
    this.getGroups()
    this.debounceExecuter()
  },
  computed: {
    events() {
      return {
        input: e => this.handleInputFilter(e),
      }
    },
    productsList() {
      if (this.onlySelected) {
        const filtered = this.products.reduce((acc, obj) => {
          const r = obj.props.variants.filter(i => 0 < i.count)
          if (0 < r.length) {
            acc.push({ ...obj, props: { ...obj.props, variants: r } })
          }
          return acc
        }, [])
        return filtered
      }
      return this.products
    },
    getProductsCount() {
      const count = this.products.reduce((acc, obj) => {
        const r = obj.props.variants.filter(i => 0 < i.count)
        if (0 < r.length) {
          r.forEach(e => acc += e.count)
        }
        return acc
      }, 0)
      return count
    },
  },
  methods: {
    prepareProduct(item) {
      const entity = {
        element: {
          id: item.id,
          type: 'store.productvariant',
        },
        quantity: item.count,
      }
      const entities = []
      entities.push(entity)
      return entities
    },
    getCartEntities() {
      const entities = this.products.reduce((acc, obj) => {
        const r = obj.props.variants.filter(i => 0 < i.count)
        if (0 < r.length) {
          r.forEach(e => acc.push(this.prepareProduct(e)[0]))
        }
        return acc
      }, [])
      return entities
    },
    async addToCart() {
      const entities = this.getCartEntities()
      await window.totisAddToCart(entities)
      this.$emit('close')
    },
    addToPreCart(data) {
      changeCartEntity.execute({}, data)
    },
    save(item, group) {
      const r = this.products.find(i => i.id === group.id)
      if (r) {
        if (item.count) {
          const o = r.props.variants.find(i => i.id === item.id)
          if (o) {
            o.count = item.count
          }
        }
      }
      this.saved = this.products
      const entities = this.getCartEntities()
      this.debounceCartCalc({ entities })
    },
    debounceCartCalc(parameters) {
      const time = 1000
      const action = debounce(this.cartExecuter, time)
      action(parameters)
    },
    debounceExecuter(parameters) {
      const time = 1000
      const action = debounce(this.executer, time)
      action(parameters)
    },
    async getGroups() {
      const { data: { items, pagination } } = (await GroupParametersGroupsResource.execute({
        id: this.info.product_id,
        limit: 100,
      }))
      this.groups = items
      this.groups_pagination = pagination
    },
    cartExecuter(parameters) {
      if (this.currentCartRequest) this.currentCartRequest.cancel()
      const cancelController = new AbortController();
      const base = postResource.config('signal', cancelController.signal)
      const resource = createResource(PRECART_CHANGE_URL, base)

      this.currentCartRequest = cancellablePromiseProxy(
        new Promise((resolve, reject) => {
          resource.execute({}, parameters)
            .then(resolve, reject);
        }),
        { cancelController }
      ).then(({ data }) => {
        console.log(data);
        this.results = data
        this.load = false
      })
    },
    executer() {
      if (this.currentRequest) this.currentRequest.cancel()
      const cancelController = new AbortController();
      const base = getResource.config('signal', cancelController.signal)
      const resource = createResource(GROUP_PARAMETERS_PRODUCT_URL, base)

      this.currentRequest = cancellablePromiseProxy(
        new Promise((resolve, reject) => {
          resource.execute({
            id: this.info.product_id,
            group_ids: this.checked,
            search: this.search,
          })
            .then(resolve, reject);
        }),
        { cancelController }
      ).then(({ data }) => {
        this.products = data.map(item => {
          const g = this.saved.find(i => i.id === item.id)
          const r = item.props.variants.map(variant => ({
            ...variant, count: (g?.props?.variants.find(i => i.id === variant.id)?.count) || 0
          }))
          item.props.variants = r
          return { ...item }
        })
        this.load = false
      })
    },
    handleInputFilter(e) {
      console.log(e);
      this.checked = e
      this.debounceExecuter()
    },
    handleSearch() {
      this.load = true
      this.debounceExecuter()
    },
    blockWindow() {
      console.log('OPEN');
      this.$body.dataset.blocked = true
    },
    unblockWindow() {
      this.$body.dataset.blocked = false
      console.log('CLOSE')
    },
  },
}
</script>
