<template lang="pug">
include /mixins
+b.product-group-preview(
  :class='{"is-active": !!quantity}'
)
  +e.img-wrap()
    +e.img.IMG(
      v-if='item.weight.props.img'
      :src='item.weight.props.img',
      :alt='item.product.title',
      :title='item.product.title',
    )
    +e.color(
      :style='{ background: item.weight.props.color }',
      v-else
    )
  +e.number.P {{ item.weight.props.color_number }}
  +e.title.P {{ item.weight.props.name }}
  counter(
    :amount='quantity',
    :min-count='0'
    @input='updateQuantity'
    :max-count='item.stock - item.stock_in_cart'
    v-if='item.stock'
  )
  modal-trigger(
    v-else
    :user_name='username'
    :user_phone='phone'
    url='Preorder/Modal',
    name='PreOrderModal',
    :id='item.id'
    :can_order='false'
    :img='item.weight.props.img || "https://via.placeholder.com/150x150"'
    :maintitle='item.product.title'
    :description='item.product.short_description'
    :weight='item.weight.props.name'
    :stock='item.stock'
    :type='item.type',
    v-slot="{ open }"
    :classes='["videoModal"]'
    :price='getPrice()'
  )
    +b.tt-button--mini.--appearance_free.--bordered_primary.product-preorder.tt-space_mb--2.BUTTON(
      @click.prevent='open()'
    )
      +e.title.P {{ _('Предзаказ') }}
    //- :show-error='stockInfo && !stockInfo.is_valid && stockInfo.quantity > stockInfo.stock_quantity_1c'
    //- :variant='product.element.id'
    //- :key='`${product.id}_${price}`'

</template>

<script>

import { decodeHTMLEntities } from '@utils/helpers'

export default {
  name: 'GroupConfiguratorProduct',
  props: {
    item: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      quantity: 0,
      username: decodeHTMLEntities(window.request_name),
      phone: window.request_phone,
    }
  },
  watch: {
    count: {
      immediate: true,
      handler(newVal) {
        this.quantity = newVal
      },
    },
  },
  created() {
  },
  methods: {
    updateQuantity(quantity) {
      this.quantity = quantity
      this.$emit('updateQuantity', { ...this.item, count: quantity })
    },
    getPrice() {
      const [initialPrice] = this.item.price_definition.user.ranged_prices
      return initialPrice.sale_price || initialPrice.default_price
    },
  },
}
</script>
