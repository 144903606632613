import {
  createResource,
  postResource,
  patchResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'cart'
const p = postfix => MODEL + postfix
const GIFT_MODEL = p('/cart-items/gifts')

const CART_CHANGE_URL = prefixAPI('/change-retrieve/{?for_preorder}', MODEL)
export const PRECART_CHANGE_URL = prefixAPI('/change-retrieve-one-off/', MODEL)
const NEW_CART_CHANGE_URL = prefixAPI('/change-retrieve-new/{?for_preorder}', MODEL)
const CART_CLEAR_URL = prefixAPI('/clear/{?for_preorder}', MODEL)
const CART_RETRIEVE_URL = prefixAPI('/retrieve/{?for_preorder}', MODEL)
const CART_INFO_RETRIEVE_URL = prefixAPI('/quantity/{?for_preorder}', MODEL)
const CART_COUPON_URL = prefixAPI('/promocode/', MODEL)

const CART_GET_GIFTS_URL = prefixAPI('/list/{id}/{?limit,offset}', GIFT_MODEL)
const CART_CHANGE_GIFT_URL = prefixAPI('/change/{id}/', GIFT_MODEL)

const CART_CHANGE_GIFT_COUNT_URL = prefixAPI('/change-quantity/{id}/', GIFT_MODEL)
const CART_CREATE_GIFT_URL = prefixAPI('/bulk-create/', GIFT_MODEL)
const CART_CLEAN_GIFT_URL = prefixAPI('/clean-group-relation/', GIFT_MODEL)

const CART_CHECK_STOCK_URL = prefixAPI('/available-stock/', MODEL)
const CART_CHECK_BY_SALE_URL = prefixAPI('/change-retrieve-one-off/', MODEL)
const CART_CLEAR_BY_SALE_URL = prefixAPI('/change-retrieve-one-off/', MODEL)

const PRODUCT_PREORDER_COUNT_URL = prefixAPI('external/preorder-stock/{?items}')

export const getPreorderCount = createResource(PRODUCT_PREORDER_COUNT_URL)
export const changeCartEntity = createResource(CART_CHANGE_URL, postResource)
export const changePrecartEntity = createResource(PRECART_CHANGE_URL, postResource)
export const newChangeCartEntity = createResource(NEW_CART_CHANGE_URL, postResource)
export const clearCartEntity = createResource(CART_CLEAR_URL, postResource)
export const addCartCoupon = createResource(CART_COUPON_URL, postResource)
export const retrieveCartEntity = createResource(CART_RETRIEVE_URL)
export const retrieveCartInfo = createResource(CART_INFO_RETRIEVE_URL)

export const retrieveGiftsList = createResource(CART_GET_GIFTS_URL)
export const changeGifts = createResource(CART_CHANGE_GIFT_URL, patchResource)
export const changeGiftAmount = createResource(CART_CHANGE_GIFT_COUNT_URL)
export const createGifts = createResource(CART_CREATE_GIFT_URL, postResource)
export const cleanGifts = createResource(CART_CLEAN_GIFT_URL, postResource)

export const checkCartStock = createResource(CART_CHECK_STOCK_URL)
export const checkCartBySale = createResource(CART_CHECK_BY_SALE_URL, postResource)
export const clearCartBySale = createResource(CART_CLEAR_BY_SALE_URL, postResource)
